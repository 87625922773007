<template>
  <div class="tc-system_safety" v-loading="loading" :element-loading-text="loadingText">
    <el-form ref="form" :model="form" label-width="180px">
      <el-form-item label="是否开启安全验证：">
        <el-radio-group v-model="form.security_verification">
          <el-radio label="1">开启</el-radio>
          <el-radio label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="连续错误几次触发：">
        <el-radio-group v-model="form.error_times">
          <el-radio label="3">三次</el-radio>
          <el-radio label="4">四次</el-radio>
          <el-radio label="5">五次</el-radio>
          <el-radio label="6">六次</el-radio>
          <el-radio label="7">七次</el-radio>
          <el-radio label="8">八次</el-radio>
          <el-radio label="9">九次</el-radio>
          <el-radio label="10">十次</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="连续错误限登时间：">
        <el-radio-group v-model="form.login_time_limit">
          <el-radio label="1">1分钟</el-radio>
          <el-radio label="3">3分钟</el-radio>
          <el-radio label="5">5分钟</el-radio>
          <el-radio label="8">8分钟</el-radio>
          <el-radio label="10">10分钟</el-radio>
          <el-radio label="15">15分钟</el-radio>
          <el-radio label="30">30分钟</el-radio>
          <el-radio label="60">1小时</el-radio>
          <el-radio label="180">3小时</el-radio>
          <el-radio label="360">6小时</el-radio>
          <el-radio label="720">12小时</el-radio>
          <el-radio label="1440">1天</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="连续错误方式：">
        <el-radio-group v-model="form.error_type">
          <el-radio label="1">全部错误</el-radio>
          <el-radio label="0">连续错误</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="限登目标：">
        <el-radio-group v-model="form.astrict_tag">
          <el-radio label="1">同一IP来源</el-radio>
          <el-radio label="0">同一设备</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="center" style="margin-top:30px">
      <el-button type="primary" @click="sevaData">保存设置</el-button>
    </el-row>
  </div>
</template>
<script>
import { selectConfig, updateConfig } from "@/api/comm.js";
export default {
  data() {
    return {
      loadingText: "页面加载中",
      loading: true,
      form: {
        security_verification: "",
        error_times: "",
        login_time_limit: "",
        error_type: "",
        astrict_tag: ""
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      selectConfig({ config_type: "system_safe" }).then(res => {
        if (res.code == 400200) {
          this.form = res.data;
          this.loading = false;
        }
      });
    },
    sevaData() {
      this.loadingText="正在更新系统配置";
      this.loading = true;
      updateConfig(this.form).then(res => {
        if (res.code == 400200) {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success"
          });
        }
      });
    }
  }
};
</script>
<style lang="less">
.tc-system_safety {
  .el-form-item {
    margin-bottom: 8px;
  }
}
</style>